import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
import CardBody from "../../views/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "../utils/snackbar/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./styles.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { postRawData } from "../../restfulservices/httpHandler.js";

const useStyles = makeStyles((theme) => ({
  snackbarContent: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
  },
}));

// Component to send a new broadcast notification
const BroadCastNotifications = ({ user, history }) => {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = { title, message };

    try {
      await postRawData(`/v1/notification/broadcastNotification`, payload);
      setAlert({
        open: true,
        message: "Broadcast sent successfully!",
        severity: "success",
      });
      setTitle("");
      setMessage("");
    } catch (error) {
      setAlert({
        open: true,
        message: `An error occurred: ${error.message}`,
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAlertClose = () => {
    setAlert({ ...alert, open: false });
  };

  

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={styles.cardHeader}>
              <h4>Broadcast Notifications</h4>
            </div>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <div className={styles.loader}>
                <CircularProgress />
              </div>
            )}
            <div className={styles.container}>
              <div className={styles.containerContent}>
                <div className={styles.content}>
                  <div className={styles.row}>
                    <TextField
                      autoComplete="title"
                      name="title"
                      variant="outlined"
                      className={styles.textField}
                      id="title"
                      label="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                      autoComplete="message"
                      name="message"
                      variant="outlined"
                      className={styles.textField}
                      id="message"
                      label="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div className={styles.footer}>
                    <div className={styles.row} style={{ gap: "30px" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        {isLoading ? "Sending..." : "Send Broadcast"}
                      </Button>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>

      <Snackbar
        open={alert.open}
        handleClose={handleAlertClose}
        message={alert.message}
        severity={alert.severity}
        ContentProps={{
          classes: {
            root: classes.snackbarContent,
          },
        }}
      />
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});

export default withRouter(connect(mapStateToProps)(BroadCastNotifications));
