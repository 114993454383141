import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import style from "./Styles.module.css";
import Divider from "@material-ui/core/Divider";
import Person from "@material-ui/icons/Person";
import Dialog from "@material-ui/core/Dialog";
import CryptoJS from "crypto-js";
import Notifications from "@material-ui/icons/Notifications";
import Button from "../CustomButtons/Button.js";
import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { getData, putRawData } from "../../restfulservices/httpHandler.js";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles, (theme) => ({
  ...styles(theme),
  notificationItem: {
    backgroundColor: (props) =>
      props.notification.markedAs === "Read" ? "white" : "black",
    color: "black",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  buttonEffect: {
    transition: "transform 0.2s ease-in-out  box-shadow 0.2s ease-out",

    "&:hover": {
      transform: "scale(1) ease-out",
      backgroundColor: "secondary",
    },
    "&:active": {
      transform: "scale(0.95)",
    },
  },
 
}));
const readOnlyStyle = {
  backgroundColor: "#f0f0f0",
  cursor: "not-allowed",
};


export default function AdminNavbarLinks(props) {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = useState(null);
  const [openProfile, setOpenProfile] = useState(null);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [user, setUser] = useState({});
  const history = useHistory();
  const encryptionKey = process.env.REACT_APP_SECRET_KEY;
  // const classes = useStyles();
  const handleSeeAll = () => {
    handleCloseNotification();
    history.push("/admin/allNotifications");
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await getData(
          "v1/notification/getUnreadNotificationsforAdmin"
        );
        setNotifications(
          Array.isArray(response?.data?.data?.data)
            ? response?.data?.data?.data
            : []
        );
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setNotifications([]);
      }
    };
    fetchNotifications();
    const userProfile = localStorage.getItem("user");
    setUser(JSON.parse(userProfile));
    console.log(userProfile, "user", user);
  }, []);
  const decryptEmail = (encryptedEmail) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedEmail, encryptionKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Decryption failed", error);
      return "Decryption Error";
    }
  };

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const markNotificationAsRead = async (id) => {
    try {
      await putRawData(`v1/notification/markReadNotification`, { id });
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification._id === id
            ? { ...notification, markedAs: "Read" }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleNotificationClick = (notification) => {
    markNotificationAsRead(notification._id);

    switch (notification.type) {
      case "allwithdrawalrequests":
        history.push("/admin/allwithdrawalrequests");
        break;
      case "support":
        history.push("/admin/support");
        break;
      case "verifyUser":
        history.push("/admin/verifyUser");
        break;
      default:
        history.push("/");
    }
  };

  const markAllAsRead = async () => {
    try {
      await getData("v1/notification/updateNotification");
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({
          ...notification,
          markedAs: "Read",
        }))
      );
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const goToProfile = () => {
    setProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setProfileModalOpen(false);
  };

  const getNotificationColor = (type) => {
    switch (type) {
      case "allwithdrawalrequests":
      case "support":
      case "verifyUser":
        return "#e2e3e5";
      default:
        return "#e2e3e5";
    }
  };

  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          {notifications.length > 0 && (
            <span className={classes.notifications}>
              {notifications.length}
            </span>
          )}
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu" className={classes.notificationList}>
                    <MenuItem
                      disabled
                      className={`${classes.dropdownHeader} notificationHeader`}
                    >
                      <strong style={{ color: "black" }}>Notification</strong>
                    </MenuItem>
                    <Divider light />
                    {Array.isArray(notifications) &&
                      notifications.map((notification, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleNotificationClick(notification)}
                          className={`notificationItem ${classes.dropdownItem}`}
                          style={{
                            backgroundColor:
                              notification.markedAs === "Read"
                                ? "white"
                                : getNotificationColor(notification.type),
                            color:
                              notification.markedAs === "Read"
                                ? "black"
                                : "black",
                          }}
                        >
                          <div>
                            <h5>{notification.title}</h5>
                            <p>{notification.message}</p>
                          </div>
                        </MenuItem>
                      ))}
                    <Divider light />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                      }}
                    >
                      <Button
                        color="primary"
                        simple
                        size="small"
                        className={`seeAllButton buttonEffect`}
                        onClick={handleSeeAll}
                      >
                        See All
                      </Button>

                      <Button
                        color="primary"
                        simple
                        size="small"
                        className={`markAllAsReadButton buttonEffect`}
                        onClick={markAllAsRead}
                      >
                        Mark All As Read
                      </Button>
                    </div>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>

      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={goToProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Log out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>

      <Dialog
        open={profileModalOpen}
        user={user}
        onClose={handleCloseProfileModal}
        scroll={"paper"}
        maxWidth={"md"}
        fullWidth={true}
      >
        <div className={style.container}>
          <div className={style.containerContent}>
            <form>
              <div className={style.header}>
                <h1>Profile Details</h1>
                <IconButton
                  onClick={() => {
                    handleCloseProfileModal();
                  }}
                >
                  <CloseRoundedIcon />
                </IconButton>
              </div>

              <div className={style.content}>
                <div className={style.row}>
                  <TextField
                    autoComplete="name"
                    name="name"
                    variant="outlined"
                    value={user?.name}
                    className={`${style.textField} ${style.readOnlyField}`}
                    id="name"
                    label="Name"
                    InputProps={{
                      readOnly: true,
                      style: readOnlyStyle,
                    }}
                  />
                  <TextField
                    autoComplete="email"
                    name="email"
                    variant="outlined"
                    className={`${style.textField} ${style.readOnlyField}`}
                    value={decryptEmail(user?.email)}
                    id="email"
                    label="Email"
                    InputProps={{
                      readOnly: true,
                      style: readOnlyStyle,
                    }}
                  />
                </div>
                {/* <div className={style.row}>
                <TextField
                  autoComplete="Description"
                  name="Description"
                  value={props?.userData?.description}
                  variant="outlined"
                  className={`${style.textField} ${style.readOnlyField}`}
                  id="Description"
                  label="Description"
                  InputProps={{
                    readOnly: true,
                    style: readOnlyStyle,
                  }}
                />
               
              </div> */}
                <div className={style.row}>
                  <TextField
                    autoComplete="Role"
                    name="Role"
                    variant="outlined"
                    value={user?.role}
                    className={`${style.textField} ${style.readOnlyField}`}
                    id="Role"
                    label="Role"
                    InputProps={{
                      readOnly: true,
                      style: readOnlyStyle,
                    }}
                  />
                  {/* <TextField
                    autoComplete="ProfileType"
                    name="ProfileType"
                    variant="outlined"
                    className={`${style.textField} ${style.readOnlyField}`}
                    value={user?.profileType}
                    id="ProfileType"
                    label="ProfileType"
                    InputProps={{
                      readOnly: true,
                      style: readOnlyStyle,
                    }}
                  /> */}
                </div>
                {props?.userData?.image && (
                  <div className={style.row}>
                    <img src={props?.userData?.image} alt="Image" />
                  </div>
                )}
              </div>
              <Divider />
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
