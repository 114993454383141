import React from "react";

// core components
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
// import Table from "../../views/Table/Table.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
// import moment from "moment";
import CardBody from "../../views/Card/CardBody.js";
import { useHistory } from "react-router-dom";
import style from "../Tickets/styles.module.css";
// import Button from "@material-ui/core/Button";
// import GiftModal from "./Modal/GiftModal";
// import IconButton from "@material-ui/core/IconButton";
// import EditRoundedIcon from "@material-ui/icons/EditRounded";
// import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
// import InputRoundedIcon from '@material-ui/icons/InputRounded';
import Snackbar from "../utils/snackbar/Snackbar";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
// import TopBar from "../utils/Topbar/TopBar.js";
import { connect } from "react-redux";
// import ConfirmAlert from "../utils/ConfirmAlert/ConfirmAlert.js";
// import SelectComp from "../utils/Select/SelectComp";
// import Dropdown from "react-mui-multiselect-dropdown";
import { useEffect, useState } from "react";
import { deleteData, getData } from "../../restfulservices/httpHandler.js";
// import { Pagination } from "@material-ui/lab";
// import { Tooltip } from "@material-ui/core";
// import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

// import Table from '@material-ui/core/Table';

const Dashboard = (props) => {
  // const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = React.useState(false);
  // const [searchValue, setSearchValue] = React.useState("");
  // const [categoryCount, setCategoryCount] = useState(null);
  // const [selectValue, setSelectValue] = React.useState("ALL");
  const [alert, setAlert] = React.useState(false);
  // const [addModal, setAddModal] = React.useState(false);
  // const [editModal, setEditModal] = React.useState(false);
  // const [activeUser, setActiveUser] = React.useState(false);
  // const [userType, setUserType] = React.useState([]);
  // const [page, setPage] = React.useState(1);
  const history = useHistory();

  const handleCardClick = () => {
    history.push("/admin/paymenthistory");
  };
  const handleCardClick2 = () => {
    history.push("/admin/pendingwithdrawalrequests");
  };
  useEffect(() => {
    getData(`v1/ticket/getTotalRevenue`).then((res) => {
      console.log("this is filtered data", res);
      setFilteredData(res?.data?.data?.amount);
    });
  }, []);
  return (
    <GridContainer>
      <Snackbar open={alert} handleClose={() => setAlert(false)} />

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={style.cardHeader}>
              <h4>Dashboard</h4>
            </div>
          </CardHeader>
          {!filteredData ? (
            <div className={style.loader}>
              <CircularProgress />
            </div>
          ) : (
            <CardBody>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <Card className={style.card}>
                  <CardHeader color="primary">
                    <div className={style.cardHeader}>
                      <h4>Payments</h4>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <Card className={style.payment} onClick={handleCardClick}>
                        <CardBody>
                          <h4 style={{ textAlign: "center" }}>
                            Payment History
                          </h4>
                        </CardBody>
                      </Card>
                      <Card
                        className={style.payment}
                        onClick={handleCardClick2}
                      >
                        <CardBody>
                          <h4 style={{ textAlign: "center" }}>
                            Payment Requests
                          </h4>
                        </CardBody>
                      </Card>
                    </div>
                  </CardBody>
                </Card>
                <Card className={style.card}>
                  <CardHeader color="primary">
                    <div className={style.cardHeader}>
                      <h4>Revenue</h4>
                    </div>
                  </CardHeader>{" "}
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card className={style.revenue}>
                        <CardBody className={style.cardBody}>
                          <h4 style={{ textAlign: "center" }}>
                            ₹ {filteredData}
                          </h4>
                        </CardBody>
                      </Card>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </CardBody>
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});
export default withRouter(connect(mapStateToProps)(Dashboard));
