import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { postFormData } from "../../restfulservices/httpHandler";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "../Stripe/PendingPage.css";

const InstagramVerification = () => {
  const location = useLocation();
  const navigate = useHistory();

  useEffect(() => {
    const getCodeFromUrl = async () => {
      const searchParams = new URLSearchParams(location.search);
      const codeParam = searchParams.get("code");
      const userParams = searchParams.get("userId");
      const denied = searchParams.get("error_reason");
      console.log(denied, "this is denied");

      if (denied) {
        navigate.push("/instagram-failure");
      }

      if (userParams) {
        localStorage.setItem("userId", userParams);
      }
      if (!codeParam && !denied) {
        window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_INSTAGRAM_REDIRECT_URL}&scope=instagram_business_basic&response_type=code`;
      }

      console.log(codeParam, userParams, "this is userid or code");

      if (codeParam) {
        const userID = localStorage.getItem("userId");

        const jsonData = {
          code: codeParam,
        };
        if (userID) {
          jsonData.userId = userID;
        }

        try {
          const response = await fetch(
            "https://prod-api.jamrack.net/v1/auth/getInstagramAccessToken",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json", // Change this if sending form data
                // Include any other headers as needed
              },
              body: JSON.stringify(jsonData), // Convert the object to JSON
            }
          );

          const data = await response.json();
          
          if (data?.data?.id && data.status) {
            navigate.push(`/instagram-success?instaId=${data?.data?.id}`);
          } else {
            navigate.push("/instagram-failure");
          }
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }
    };

    setTimeout(() => {
      getCodeFromUrl();
    }, 1000);
  }, [location.search]);

  return (
    <div className="pending-container">
      <div className="pending-box">
        <span
          style={{
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Instagram Verification Processing
        </span>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <CircularProgress size={80} />
        </Box>
      </div>
    </div>
  );
};

export default InstagramVerification;
