import React from "react";
import "../Stripe/FailurePage.css";

const InstagramFailure = () => {
  return (
    <div className="failure-container">
      <div className="failure-box">
        <h1>Oops!</h1>
        <p>Login Failed. Please try again.</p>
      </div>
    </div>
  );
};

export default InstagramFailure;
