import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./login.module.css";
import { postRawData, putRawData } from "../../../restfulservices/httpHandler";
import { withRouter } from "react-router-dom";
import { setUser } from "../../../containers/app/actions";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import toast from "react-hot-toast";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState(null);

  useEffect(() => {
    if (props.user) {
      props.history.push("/admin/dashboard");
    }
  }, [props.user]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .min(3, "Password must be 3 characters at minimum")
      .required("Password is required"),
  });

  const saveFcmToken = async (userId, deviceToken) => {
    try {
      await putRawData("/v1/user/fcmTokenSaveWeb", { userId, deviceToken });
      console.log("FCM token saved successfully");
    } catch (error) {
      console.error("Error saving FCM token", error);
    }
  };
  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    setErrMessage(null);

    try {
      console.log(values, "this is value");

      const res = await postRawData("/v1/auth/login", values);

      if (res?.data?.data?.role === "Admin") {
        const user = res?.data?.data;
        const token = res?.data?.tokens?.access?.token;
        const userId = user._id;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", JSON.stringify(token));
        props.setUser(user);

        const deviceToken = "your_device_token_here";

        console.log("User ID:", userId);
        console.log("Device Token:", deviceToken);

        await saveFcmToken(userId, deviceToken);

        props.history.push("/admin/dashboard");
      } else {
        toast.error("You are not an admin");
        setErrMessage(res?.data?.message);
      }
    } catch (err) {
      console.log(err);
      setErrMessage("Please check the credentials again !!");
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="col-lg-12 text-center">
          <h6>{errMessage}</h6>
          <h1 className="mt-5">Login</h1>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={LoginSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      className={`form-control ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                      className={`form-control ${
                        touched.password && errors.password ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isSubmitting || loading}
                  >
                    {loading ? "Please wait..." : "Submit"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-link float-right"
                    style={{ boxShadow: "none" }}
                  >
                    Forget Password
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});

export default withRouter(connect(mapStateToProps, { setUser })(Login));
