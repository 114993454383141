import * as actionTypes from './actionTypes'

const initialState = {
  user: false,
  
} 
const reducer =  (state = initialState, action) => {
  switch (action.type) { 
    
    case actionTypes.SET_USER:
      return {...state,user: action.payload}
    default:
      return state
  }
}

export default reducer;