import React, { useEffect, useState } from "react";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import SelectComp from "../../utils/Select/SelectComp";
import { putRawData } from "../../../restfulservices/httpHandler";
import styles from "./styles.module.css";

const EditPendingModal = (props) => {
  const [status, setStatus] = useState(props?.userData?.status || "Pending");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await putRawData(
        `v1/ticket/updateWithdrawHistory?id=${props?.userData?._id}&status=${status}`,
        { status: status }
      );

      if (response?.status === false) {
        props.setAlert(response.message || "Something went wrong");
      } else {
        props.setAlert("Status Updated");
        props.onDone(); 
      }
    } catch (error) {
      props.setAlert("Error updating status");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={styles.container}>
        <div className={styles.containerContent}>
          <form onSubmit={handleSubmit}>
            <div className={styles.header}>
              <h1>Edit Status</h1>
              <IconButton onClick={props.onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </div>

            <div className={styles.content}>
              <div className={styles.row}>
                <SelectComp
                  name="Status"
                  variant="outlined"
                  id="type"
                  className={styles.textField}
                  value={status}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                  options={["Pending", "Initiated", "Complete"]}
                  values={["Pending", "Initiated", "Complete"]}
                />
              </div>
            </div>
            <Divider />
            <div className={styles.footer}>
              {loading ? (
                <Button variant="contained" color="secondary" disabled>
                  Loading....
                </Button>
              ) : (
                <Button type="submit" variant="contained" color="secondary">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});

export default connect(mapStateToProps)(EditPendingModal);
