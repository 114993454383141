import DashboardIcon from "@material-ui/icons/Dashboard";
import Dashboard from "./components/Dashboard/Dashboard";
import BroadCastNotifications from "./components/BroadcastNotification/BroadCastNotifications";
// import Users from "./components/Users/Users";
import ManageUser from "./components/ManageUser/ManageUser";
import ListIcon from "@material-ui/icons/List";
// import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
// import ArrowRightIcon from "@material-ui/icons/ArrowRight";
// import BorderColorIcon from "@material-ui/icons/BorderColor";
import VerifiedIcon from "@mui/icons-material/Verified";
import ApartmentIcon from "@material-ui/icons/Apartment";
// import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ManageContent from "./components/ManageContent/ManageContent";
import Category from "./components/Category/Category";
import Gifts from "./components/Gift/Gifts";
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import CardGiftcard from "@material-ui/icons/CardGiftcard";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import FeedbackIcon from "@mui/icons-material/Feedback";
import Ticket from "./components/Tickets/Ticket";
import Feedback from "./components/Support/Support";
import AllRequests from "./components/AllWithdrawal/AllRequests";
import PendingIcon from "@mui/icons-material/Pending";
import PendingWithdrawal from "./components/PendingWithdrawal/PendingWithdrawal";
import Notifications from "./components/Notifications/Notifications";
import VerifyUser from "./components/VerifyUser/VerifyUser";
import { BroadcastOnHome, BroadcastOnHomeRounded } from "@mui/icons-material";
import BroadcastHistory from "./components/BroadcastNotification/BroadCastHistory";

import InstagramVerification from "./components/instagram_verification/InstagramVerification";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },

  // {
  //   path: "/users",
  //   name: "Users",
  //   icon: PeopleAltRoundedIcon,
  //   component: Users,
  //   layout: "/admin",
  // },

  {
    path: "/manageuser",
    name: "ManageUser",
    icon: PersonOutlineIcon,
    component: ManageUser,
    layout: "/admin",
  },
  {
    path: "/managecontent",
    name: "ManageContent",
    icon: ApartmentIcon,
    component: ManageContent,
    layout: "/admin",
  },
  {
    path: "/category",
    name: "Category",
    icon: ListIcon,
    component: Category,
    layout: "/admin",
  },
  {
    path: "/gifts",
    name: "Gifts",
    icon: CardGiftcard,
    component: Gifts,
    layout: "/admin",
  },
  {
    path: "/ticket",
    name: "Ticket",
    icon: ConfirmationNumberIcon,
    component: Ticket,
    layout: "/admin",
  },
  {
    // path: "/broadcastNotification",
    name: "BroadCast",
    icon: BroadcastOnHome,
    // component: BroadCastNotifications,
    // layout: "/admin",
    subMenu: [
      {
        path: "/broadcastNotification",
        name: "Notification",
        icon: BroadcastOnHome,
        // component: BroadCastNotifications,
        layout: "/admin",
      },
      {
        path: "/broadcastNotificationhistory",
        name: "Broadcast History",
        icon: ConfirmationNumberIcon,
        // component: BroadcastHistory,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/broadcastNotification",
    name: "BroadCast Notification",
    icon: BroadcastOnHome,
    component: BroadCastNotifications,
    layout: "/admin",
    dontShow: true,
  },
  {
    path: "/broadcastNotificationhistory",
    name: "Broadcast History",
    icon: ConfirmationNumberIcon,
    component: BroadcastHistory,
    layout: "/admin",
    dontShow: true,
  },

  {
    path: "/verifyUser",
    name: "Verify User",
    icon: VerifiedIcon,
    component: VerifyUser,
    layout: "/admin",
  },
  // {
  //   path: "/notification",
  //   name: "Notification",
  //   icon: NotificationsIcon,
  //   component: Notifications,
  //   layout: "/admin",
  // },
  {
    path: "/allNotifications",
    name: "AllNotifications",
    component: Notifications,

    layout: "/admin",
    dontShow: true, // hide route
  },
  {
    path: "/paymenthistory",
    name: "AllWithdrawalRequests",
    component: AllRequests,

    layout: "/admin",
    dontShow: true,
  },
  {
    path: "/pendingwithdrawalrequests",
    name: "Pending Requests",
    component: PendingWithdrawal,
    icon: PendingIcon,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "Support",
    icon: FeedbackIcon,
    component: Feedback,
    layout: "/admin",
  },
];

export default dashboardRoutes;
