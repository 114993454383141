import React from 'react';
import './FailurePage.css';

const FailurePage = () => {

  return (
    <div className="failure-container">
      <div className="failure-box">
        <h1>Oops!</h1>
        <p>Something went wrong. Please try again.</p>
      </div>
    </div>
  );
};

export default FailurePage;
