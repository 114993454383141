import React, { useEffect, useState } from "react";
import style from "./styles.module.css";
import TextField from "@material-ui/core/TextField";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import SelectComp from "../../utils/Select/SelectComp";
import CryptoJS from "crypto-js"; 
import { putRawData } from "../../../restfulservices/httpHandler";

// Define custom styles for read-only fields
const readOnlyStyle = {
  backgroundColor: '#f0f0f0', 
  cursor: 'not-allowed',
};

const SupportModal = (props) => {
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const encryptionKey = process.env.REACT_APP_SECRET_KEY; 

  const decryptEmail = (encryptedEmail) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedEmail, encryptionKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Decryption failed", error);
      return "Decryption Error";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await putRawData(`v1/user/updateSupport?id=${props?.userData?._id}`, {
      status: status,
    });

    props.setAlert("Status Updated");
    setLoading(false);
    props.onDone();
  };

  useEffect(() => {
    if (props.type === "EDIT") {
      setStatus(props?.userData?.status);
    }
  }, [props]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={"paper"}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <div className={style.container}>
        <div className={style.containerContent}>
          <form onSubmit={handleSubmit}>
            <div className={style.header}>
              <h1>Edit Support</h1>
              <IconButton
                onClick={() => {
                  props.onClose();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </div>

            <h2>Support Details</h2>
            <div className={style.content}>
              <div className={style.row}>
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  value={props?.userData?.userId?.name}
                  className={`${style.textField} ${style.readOnlyField}`}
                  id="name"
                  label="Name"
                  InputProps={{
                    readOnly: true,
                    style: readOnlyStyle,
                  }}
                />
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  className={`${style.textField} ${style.readOnlyField}`}
                  value={decryptEmail(props?.userData?.userId?.email)}
                  id="email"
                  label="Email"
                  InputProps={{
                    readOnly: true,
                    style: readOnlyStyle,
                  }}
                />
              </div>
              <div className={style.row}>
              <TextField
                  autoComplete="Subject"
                  name="Subject"
                  variant="outlined"
                  value={props?.userData?.subject}
                  className={`${style.textField} ${style.readOnlyField}`}
                  id="Subject"
                  label="Subject"
                  InputProps={{
                    readOnly: true,
                    style: readOnlyStyle,
                  }}
                />
                <TextField
                  autoComplete="Description"
                  name="Description"
                  value={props?.userData?.description}
                  variant="outlined"
                  className={`${style.textField} ${style.readOnlyField}`}
                  id="Description"
                  label="Description"
                  InputProps={{
                    readOnly: true,
                    style: readOnlyStyle,
                  }}
                />
                
                
              </div>
              <div className={style.row}>
               
                <TextField
                  autoComplete="ProfileType"
                  name="ProfileType"
                  variant="outlined"
                  className={`${style.textField} ${style.readOnlyField}`}
                  value={props?.userData?.userId?.profileType}
                  id="ProfileType"
                  label="ProfileType"
                  InputProps={{
                    readOnly: true,
                    style: readOnlyStyle,
                  }}
                  
                />
                <SelectComp
                  name="status"
                  variant="outlined"
                  id="type"
                  className={style.textField}
                  value={status}
                  label="Status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  options={["Pending", "Inprocess", "Completed"]}
                  values={["Pending", "Inprocess", "Completed"]}
                />
              </div>
              {props?.userData?.image && ( 
                <div className={style.row}>
                  <img src={props?.userData?.image} alt="Image" />
                </div>
              )}
            </div>
            <Divider />
            <div className={style.footer}>
              {loading ? (
                <Button variant="contained" color="secondary">
                  Loading....
                </Button>
              ) : (
                <Button type="submit" variant="contained" color="secondary">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});

export default connect(mapStateToProps)(SupportModal);
