import React, { useState, useEffect } from "react";
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
import Table from "../../views/Table/Table.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
import style from "./styles.module.css";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import CardBody from "../../views/Card/CardBody.js";
import { getData } from "../../restfulservices/httpHandler";
import CircularProgress from "@material-ui/core/CircularProgress";

const BroadcastHistory = () => {
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchNotifications();
  }, [page, rowsPerPage]);

  const formatDateTime = (date) => {
    return moment(date).format("Do MMM YYYY, h:mm:ss a");
  };

  const truncateMessage = (message, maxLength = 180) => {
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + "...";
    }
    return message;
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getData("/v1/notification/getAllBroadcast", {
        params: {
          page: page,
          limit: rowsPerPage,
        },
      });

      if (response && response.data) {
        setNotifications(response.data.data.broadcast || []);
        setTotalNotifications(response.data.data.count || 0);
      } else {
        setNotifications([]);
        setTotalNotifications(0);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]);
      setTotalNotifications(0);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const details = notifications.map((val, index) => [
    index + 1,
    val.title || "No Title",
    truncateMessage(val.message || "No Message"),
    formatDateTime(val.createdAt) || "No Date",
  ]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={style.cardHeader}>
              <h4>Broadcast History</h4>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div className={style.loader}>
                <CircularProgress />
              </div>
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={["S.N", "Title", "Message", "Sent on"]}
                tableData={details}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>

      <div
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          count={Math.ceil(totalNotifications / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </GridContainer>
  );
};

export default BroadcastHistory;
