import React from 'react';
import './SuccessPage.css';

const SuccessPage = () => {

    return (
        <div className="success-container">
            <div className="success-box">
                <h1>Success!</h1>
                <p>Your account has been created successfully.</p>
            </div>
        </div>
    );
};

export default SuccessPage;
