import React from "react";
import Modal from "@material-ui/core/Modal";
import styles from "./styles.module.css";
import TextField from "@material-ui/core/TextField";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import SelectComp from "../../utils/Select/SelectComp";
import SelectSearch from "../../utils/Selectsearch/SelectSearch";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { postRawData, putRawData } from "../../../restfulservices/httpHandler";
import { MenuItem, Select } from "@material-ui/core";
// import {getUserType} from '../../../containers/app/actions'

const CategoryModal = (props) => {
  const [id, setId] = useState(null);

  const SubmitSchema = Yup.object({
    name: Yup.string("Enter category name")
      .min(2, "Too Short!")
      .required("Name is Required"),
    status: Yup.string("Enter Status").required("Status is Required"),
    description: Yup.string("Enter description")
      .min(10, "Description should be more than 10 words")
      .required("Description is Required"),
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.type === "EDIT") {
      formik.setValues({
        name: props?.userData?.name,
        description: props?.userData?.description,
        status: props?.userData?.status,
      });

      setId(props?.userData?._id);
      console.log(props?.userData);
    }
  }, [props]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      status: "Active",
    },
    validationSchema: SubmitSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("hey", values);

      setLoading(true);

      const isEdit = props.type === "EDIT";
      const url = isEdit
        ? `v1/category/updateCategoryById/${id}`
        : `v1/category/createCategory`;
      const request = isEdit ? putRawData : postRawData;

      request(url, values)
        .then((res) => {
          if (res.data.status) {
            props.onDone();
            props.setAlert("Successfully Done");
          } else {
            props.setAlert("Something went wrong! Try Again");
          }
        })
        .catch((err) => {
          console.log(err);
          props.setAlert("Something went wrong! Try Again");
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
          resetForm();
        });
    },
  });
  //   const onSubmit = () => {
  //     console.log("hey");
  //     console.log("this is formdata", formData);
  //     setLoading(true);
  //     const type = props.type === "EDIT" ? "EDIT" : "ADD";
  //     postRawData(`v1/category/createCategory`, formData)
  //       .then((res) => {
  //         if (res.data.status) {
  //           setFormData({
  //             name: "",
  //             description: "",
  //             status: "",
  //           });
  //           props.onDone();
  //           setLoading(false);
  //           props.setAlert("Succesfully Done");
  //         } else {
  //           setLoading(false);
  //           props.setAlert("Something went wrong !Try Again");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setLoading(false);
  //         props.setAlert("Something went wrong !Try Again");
  //       });
  //   };

  let btnText = props.type === "EDIT" ? "Update Category" : "Add Category";
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={"paper"}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <div className={styles.container}>
        <div className={styles.containerContent}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.header}>
              <h1>{btnText}</h1>
              <IconButton
                onClick={() => {
                  formik.resetForm();
                  props.onClose();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </div>

            <h2>Category Details</h2>

            <div className={styles.content}>
              <div className={styles.row}>
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  className={styles.textField}
                  id="name"
                  label="Category Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  autoComplete="description"
                  name="description"
                  variant="outlined"
                  className={styles.textField}
                  id="description"
                  label="Description"
                  type="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    !!(formik.touched.description && formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </div>

              <div className={styles.row}>
                <SelectComp
                  name="status"
                  variant="outlined"
                  id="type"
                  className={styles.textField}
                  label="Status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={["Active", "Inactive"]}
                  error={!!(formik.touched.status && formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                />

                {/* <TextField
                  name="status"
                  variant="outlined"
                  id="type"
                  className={styles.textField}
                  label="Status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={["Active", "Inactive"]}
                  error={!!(formik.touched.status && formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                  select
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Active1">Active1</MenuItem>
                </TextField> */}
              </div>
            </div>

            <Divider />

            <div className={styles.footer}>
              {loading ? (
                <Button variant="contained" color="secondary">
                  Loading....
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={formik.isSubmitting}
                >
                  {btnText}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});
export default connect(mapStateToProps)(CategoryModal);
