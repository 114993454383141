import React from "react";
//import { makeStyles, withStyles9 } from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

const SelectComp = (props) => {
  console.log(props);
  return (
    <FormControl
      style={{ ...props.style }}
      variant={props.variant ? props.variant : "outlined"}
      className={props.className}
    >
      {props.label && <InputLabel id={props.labelId}>{props.label}</InputLabel>}
      <Select
        name={props?.name || "Input"}
        labelId={props?.labelId}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        multiple={props.multiple || false}
        label={props.label}
        error={props.error}
        helperText={props.helperText}
        onBlur={props.onBlur}
        defaultValue=""
        endAdornment={
          props.deleteIcon && (
            <InputAdornment position="end">
              <IconButton onClick={props.onDelete}>
                <DeleteRoundedIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {props.options.length > 0
          ? props.options.map((val, index) => {
              return (
                <MenuItem value={val} key={index}>
                  {val}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </FormControl>
  );
};

export default SelectComp;
