import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "./components/auth/login/Login";
import Notifications from "./components/Notifications/Notifications";
import SuccessPage from "./components/Stripe/Success";
import FailurePage from "./components/Stripe/Failure";
import PendingPage from "./components/Stripe/Pending";
import AdminNavbarLinks from "./views/Navbars/AdminNavbarLinks";
import Register from "./components/auth/register/Register";
import Admin from "./layout/Admin";

import styles from "./styles/app.module.css";
import { theme } from "./theme/theme";

import { setUser } from "./containers/app/actions";
import { connect } from "react-redux";
import BackDrop from "./components/utils/BackDrop/BackDrop";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import InstagramVerification from "./components/instagram_verification/InstagramVerification";
import InstagramSuccess from "./components/instagram_verification/InstagramSuccess";
import { Instagram } from "@mui/icons-material";
import InstagramFailure from "./components/instagram_verification/InstagramFailed";

const hist = createBrowserHistory();

const App = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (localStorage.user) {
      var userData = JSON.parse(localStorage.getItem("user"));
      if (userData && userData != null) {
        props.setUser(userData);
        // console.log(userData);
      }
      setLoaded(true);
    } else {
      setLoaded(true);
    }
  }, []);

  return (
    <div className={styles.app}>
      {!loaded && <BackDrop />}

      {loaded && (
        <Router history={hist}>
          <ThemeProvider theme={theme}>
            {/* <CombineComp /> */}
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/success-page" component={SuccessPage} />
              <Route path="/failure-page" component={FailurePage} />
              <Route path="/pending-page" component={PendingPage} />
              <Route path="/instagram-success" component={InstagramSuccess} />
              <Route path="/instagram-failure" component={InstagramFailure} />

              <Route
                path="/instagram-verification"
                component={InstagramVerification}
              />
              <Admin />
              <Route path="/admin/allNotifications" component={Notifications} />
              {/* <Route path="/" component={AdminNavbarLinks} /> */}
            </Switch>
          </ThemeProvider>
        </Router>
      )}
      <Toaster />
    </div>
  );
};
export default connect(null, { setUser })(App);
