import React from "react";
import "../Stripe/SuccessPage.css";

const InstagramSuccess = () => {
  return (
    <div className="success-container">
      <div className="success-box">
        <h1>Success!</h1>
        <p>Instagram Login successfully.</p>
      </div>
    </div>
  );
};

export default InstagramSuccess;
