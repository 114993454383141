import React from "react";
import Modal from "@material-ui/core/Modal";
import styles from "./styles.module.css";
import TextField from "@material-ui/core/TextField";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import SelectComp from "../../utils/Select/SelectComp";
import SelectSearch from "../../utils/Selectsearch/SelectSearch";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { postRawData, putRawData } from "../../../restfulservices/httpHandler";
import { MenuItem, Select } from "@material-ui/core";
// import {getUserType} from '../../../containers/app/actions'

const GiftModal = (props) => {
  const [id, setId] = useState(null);

  const SubmitSchema = Yup.object({
    name: Yup.string("Enter gift name")
      .min(2, "Too Short!")
      .required("Name is Required"),
    status: Yup.string("Select Status").required("Status is Required"),
    size: Yup.array()
      .of(Yup.string().required("Each size is required"))
      .min(1, "At least one size is required")
      .required("Size is required"),
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.type === "EDIT") {
      formik.setValues({
        name: props?.userData?.name,
        status: props?.userData?.status,
        size: props?.userData?.size,
      });

      setId(props?.userData?._id);
      console.log(props?.userData);
    }
  }, [props]);

  const formik = useFormik({
    initialValues: {
      name: "",
      size: ["S"],
      status: "Active",
    },
    validationSchema: SubmitSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);

      const isEdit = props.type === "EDIT";
      const url = isEdit
        ? `v1/category/updateGiftById/${id}`
        : `v1/category/createGift`;
      const request = isEdit ? putRawData : postRawData;
      console.log("heueefdfggg",values);
      request(url, values)
        .then((res) => {
          if (res?.data?.status) {
            props.onDone();
            props.setAlert("Successfully Done");
          } else {
            props.setAlert("Something went wrong! Try Again");
          }
        })
        .catch((err) => {
          console.log(err);
          props.setAlert("Something went wrong! Try Again");
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
          resetForm();
        });
    },
  });

  let btnText = props.type === "EDIT" ? "Update Gift" : "Add Gift";
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={"paper"}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <div className={styles.container}>
        <div className={styles.containerContent}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.header}>
              <h1>{btnText}</h1>
              <IconButton
                onClick={() => {
                  formik.resetForm();
                  props.onClose();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </div>

            <h2>Gift Details</h2>

            <div className={styles.content}>
              <div className={styles.row}>
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  className={styles.textField}
                  id="name"
                  label="Gift Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <SelectComp
                  name="size"
                  variant="outlined"
                  className={styles.textField}
                  id="size"
                  label="Size"
                  type="size"
                  multiple="true"
                  value={formik.values.size}
                  options={["S", "M", "L", "XL"]}
                  onChange={(event) => {
                    const { value } = event.target;
                    formik.setFieldValue(
                      "size",
                      typeof value === "string" ? value.split(",") : value
                    );
                  }}
                  onBlur={formik.handleBlur}
                  error={!!(formik.touched.size && formik.errors.size)}
                  helperText={formik.touched.size && formik.errors.size}
                />
              </div>

              <div className={styles.row}>
                <SelectComp
                  name="status"
                  variant="outlined"
                  id="type"
                  className={styles.textField}
                  label="Status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={["Active", "Inactive"]}
                  error={!!(formik.touched.status && formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                />
              </div>
            </div>

            <Divider />

            <div className={styles.footer}>
              {loading ? (
                <Button variant="contained" color="secondary">
                  Loading....
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={formik.isSubmitting}
                >
                  {btnText}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});
export default connect(mapStateToProps)(GiftModal);
