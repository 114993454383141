import axios from "axios";
import config from "../config/keys";
import toast from "react-hot-toast";

const axiosInstance = axios.create({
  baseURL: config.backendUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = JSON.parse(localStorage.getItem("token"));

    if (authToken) {
      config.headers.Authorization = "Bearer " + authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const postRawData = async (url, data) => {
  try {
    console.log(data, "jfdlskf");

    const res = await axiosInstance.post(url, data);
    return res;
  } catch (error) {
    if (error.response.data?.message) {
      toast.error(error.response.data?.message);
    } else {
      toast.error("Something went wrong");
    }
    return null;
  }
};

const putRawData = async (url, data) => {
  try {
    const res = await axiosInstance.put(url, data);
    return res;
  } catch (error) {
    if (error.response.data?.message) {
      toast.error(error.response.data?.message);
    } else {
      toast.error("Something went wrong");
    }
    return null;
  }
};

const postFormData = async (url, data) => {
  try {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axiosInstance.post(url, formData);
    return response;
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Something went wrong");
    }
    return null;
  }
};

const getData = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    if (error.response.data?.message) {
      toast.error(error.response.data?.message);
    } else {
      toast.error("Something went wrong");
    }
    return null;
  }
};

const deleteData = async (url, data) => {
  try {
    const res = await axiosInstance.delete(url, data);
    return res;
  } catch (error) {
    if (error.response.data?.message) {
      toast.error(error.response.data?.message);
    } else {
      toast.error("Something went wrong");
    }
    return null;
  }
};

const updateData = async (url, data) => {
  try {
    const res = await axiosInstance.delete(url, data);
    return res;
  } catch (error) {
    if (error.response.data?.message) {
      toast.error(error.response.data?.message);
    } else {
      toast.error("Something went wrong");
    }
    return null;
  }
};

export {
  postFormData,
  postRawData,
  getData,
  putRawData,
  deleteData,
  updateData,
};
