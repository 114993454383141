import React, { useEffect, useState } from "react";
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
import Table from "../../views/Table/Table.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
import moment from "moment";
import CardBody from "../../views/Card/CardBody.js";
import style from "./styles.module.css";
import IconButton from "@material-ui/core/IconButton";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import Snackbar from "../utils/snackbar/Snackbar";
import { withRouter } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {  Tooltip } from "@material-ui/core";
import { getData } from "../../restfulservices/httpHandler.js";
import EditPendingModal from "./Modal/EditPendingModal.js";

const PendingRequests = (props) => {
  const [filteredData, setFilteredData] = useState([]);
  const [categoryCount, setCategoryCount] = useState(null);
  const [selectValue, setSelectValue] = useState("All");
  const [alert, setAlert] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [page, setPage] = useState(1);
  let limit = 10;

  useEffect(() => {
    fetchCategory();
  }, [alert, selectValue, page]);

  const fetchCategory = async () => {
    let url = `v1/ticket/getAllPendingWithdraw?page=${page}&limit=${limit}`;
    if (selectValue !== "All") {
      url = `v1/ticket/filterWithdraw?page=${page}&limit=${limit}&filter=${selectValue}`;
    }
    const res = await getData(url);
    setFilteredData(res?.data?.data?.history);
    setCategoryCount(res?.data?.data?.count);
  };

  const formatDate = (date) => moment(date).format("MMM Do YY");

  const handleChangePage = (event, value) => setPage(value);

  const details = filteredData.map((val, index) => [
    index + 1,
    val?.userId?.name,
    val?.status, 
    val?.withdrawBy,
    formatDate(val.createdAt),
    <React.Fragment>
      <Tooltip title="Edit">
        <IconButton
          onClick={() => {
            setActiveUser(val);
            setEditModal(true);
          }}
        >
          <EditRoundedIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>,
  ]);
  

  return (
    <GridContainer>
      {activeUser && (
        <EditPendingModal
          type="EDIT"
          userData={activeUser}
          open={editModal}
          onClose={() => setEditModal(false)}
          onDone={() => {
            setEditModal(false);
            setActiveUser(false);
            fetchCategory(); 
          }}
          setAlert={(msg) => setAlert(msg)}
        />
      )}
      <Snackbar open={alert} handleClose={() => setAlert(false)} />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={style.cardHeader}>
              <h4>Withdrawal Requests</h4>
              <select onChange={(e) => setSelectValue(e.target.value)}>
                <option value="All">All</option>
                <option value="Cheque">Cheque</option>
                <option value="Stripe">Stripe</option>
              </select>
            </div>
          </CardHeader>
          <CardBody>
            {!filteredData.length ? (
              <div className={style.loader}>
                <CircularProgress />
              </div>
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "S.N",
                  "Name",
                  "Status",
                  "WithDrawBy",
                  "Requested At",
                  "Action",
                ]}
                tableData={details}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <div
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          count={Math.ceil(parseInt(categoryCount) / limit)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});

export default withRouter(connect(mapStateToProps)(PendingRequests));
