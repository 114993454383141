import React, { useEffect, useState } from "react";
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
// import TextField from "@material-ui/core/TextField";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
import moment from "moment";
import Table from "../../views/Table/Table.js";
import IconButton from "@material-ui/core/IconButton";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import CardBody from "../../views/Card/CardBody.js";
import CryptoJS from "crypto-js"; 
import style from "./styles.module.css";
import Snackbar from "../utils/snackbar/Snackbar";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {  getData } from "../../restfulservices/httpHandler.js";
import { Pagination } from "@material-ui/lab";
import { Tooltip } from "@material-ui/core";
import VerifyModal from "./Modal/VerifyModal.js";

const VerifyUser = (props) => {
  const [alert, setAlert] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [supportcount, setSupportCount] = useState(0);

  let limit = 5;

  useEffect(() => {
    fetchCategory();
  });

  const fetchCategory = async () => {
    const res = await getData(`v1/user/requestVerifiedUsers?page=${page}&limit=${limit}`);
    const decryptionKey = process.env.REACT_APP_SECRET_KEY;

    // Decrypt emails in the fetched data
    const decryptedData = res.data.data.data.map(user => {
      const decryptedEmail = CryptoJS.AES.decrypt(user.email, decryptionKey).toString(CryptoJS.enc.Utf8);
      return { ...user, email: decryptedEmail };
    });

    console.log("this is all user verification", decryptedData);
    setSupportCount(res.data.data.count);
    setFilteredData(decryptedData);
  };

  const formatDate = (date) => {
    return moment(date).format("MMM Do YY");
  };

  const isLoading = !filteredData.length;
  const details = filteredData.map((val,index) => ([
    index + 1,
    val.name,
    val.email,
    val.profileType,
    val.role,
    val.isVerified,
    formatDate(val.createdAt),
    <React.Fragment key={val.id}>
      <Tooltip title="Edit">
        <IconButton
          onClick={() => {
            setActiveUser(val);
            setEditModal(true);
          }}
        >
          <EditRoundedIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>,
  ]));

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <GridContainer>
      {activeUser && (
        <VerifyModal
          type="EDIT"
          userData={activeUser}
          open={editModal}
          onClose={() => setEditModal(false)}
          onDone={() => {
            setEditModal(false);
            setActiveUser(false);
          }}
          setAlert={(msg) => setAlert(msg)}
        />
      )}
      <Snackbar open={alert} handleClose={() => setAlert(false)} />

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={style.cardHeader}>
              <h4> Verification Request</h4>
            </div>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <div className={style.loader}>
                <CircularProgress />
              </div>
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={["S.N","Name", "Email","Profile Type","Role", "isVerified", "Verification Date", "Actions"]}
                tableData={details}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <div style={{ width: "100vw", display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(parseInt(supportcount) / limit)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});
export default withRouter(connect(mapStateToProps)(VerifyUser));
