import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const NotificationCard = ({
  title,
  message,
  markedAs,
  id,
  type,
  markAsRead,
}) => {
  const history = useHistory();

  const handleNotificationClick = () => {
    markAsRead(id);

    // Redirect based on notification type or route
    switch (type) {
      case "withdrawal":
        history.push("/admin/allwithdrawalrequests");
        console.log("withdrawal");
        break;
      case "support":
        history.push("/admin/support");
        break;
      case "verifyUser":
        history.push("/admin/verifyUser");
        break;
      default:
        history.push("/");
    }
  };

  return (
    <Card
      sx={{
        margin: 2,
        backgroundColor: markedAs === "Read" ? "white" : "#e0e0e0",
        borderLeft:
          markedAs === "Read" ? "4px solid #4caf50" : "4px solid #ff9800",
        cursor: "pointer",
        transition: "transform 0.2s, box-shadow 0.2s",
        "&:hover": {
          transform: "scale(1.02)",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
      }}
      onClick={handleNotificationClick}
    >
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NotificationCard;
