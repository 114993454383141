import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js"; 
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
import moment from "moment";
import Table from "../../views/Table/Table.js";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CardBody from "../../views/Card/CardBody.js";
import style from "./styles.module.css";
import Snackbar from "../utils/snackbar/Snackbar.js";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { getData } from "../../restfulservices/httpHandler.js";
import { Pagination } from "@material-ui/lab";
import { Tooltip } from "@material-ui/core";
import SupportModal from "./Modal/SupportModal.js";

const Support = (props) => {
  const [alert, setAlert] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [filteredData, setFilteredData] = useState(false);
  const [page, setPage] = useState(1);
  const [supportCount, setSupportCount] = useState(false);

  let limit = 5;
  const encryptionKey = process.env.REACT_APP_SECRET_KEY;

  useEffect(() => {
    fetchCategory();
  }, [alert]);

  const fetchCategory = async () => {
    const res = await getData(
      `v1/user/getAllSupport?page=${page}&limit=${limit}`
    );
    setSupportCount(res?.data?.data?.count);
    setFilteredData(res?.data?.data?.support);
  };

  const formatDate = (date) => {
    return moment(date).format("MMM Do YY");
  };

  const decryptEmail = (encryptedEmail) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedEmail, encryptionKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Decryption failed", error);
      return "Decryption Error";
    }
  };

  const truncateText = (text, maxLength) => {
    if (!text) return ""; // Return empty string if text is undefined or null
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const isLoading = !filteredData;
  const details = [];

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  !isLoading &&
    filteredData.forEach((val, index) => {
      details.push([
        index + 1,
        val?.userId?.name || "N/A", // Fallback to "N/A" if no name
        decryptEmail(val?.userId?.email),
        val?.userId?.role || "N/A", // Fallback to "N/A" if no role
        truncateText(val?.description || "No description available", 50), // Handle undefined description
        val?.userId?.profileType || "N/A", // Fallback to "N/A" if no profile type
        formatDate(val?.createdAt),
        <React.Fragment>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                setActiveUser(val);
                setEditModal(true);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>,
      ]);
    });

  return (
    <GridContainer>
      {activeUser && (
        <SupportModal
          type="EDIT"
          userData={activeUser}
          open={editModal}
          onClose={() => setEditModal(false)}
          onDone={() => {
            setEditModal(false);
            setActiveUser(false);
          }}
          setAlert={(msg) => setAlert(msg)}
        />
      )}
      <Snackbar open={alert} handleClose={() => setAlert(false)} />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={style.cardHeader}>
              <h4>All Support</h4>
            </div>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <div className={style.loader}>
                <CircularProgress />
              </div>
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "S.N",
                  "Name",
                  "Email",
                  "Role",
                  "Description",
                  "Profile Type",
                  "Submission Date",
                  "Actions",
                ]}
                tableData={details}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <div
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          count={Math.ceil(parseInt(supportCount) / limit)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});

export default withRouter(connect(mapStateToProps)(Support));
