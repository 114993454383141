import React, { useEffect } from "react";
import styles from "../../Gift/Modal/styles.module.css";
import TextField from "@material-ui/core/TextField";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import CryptoJS from "crypto-js";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";

const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const CategoryModal = (props) => {
  useEffect(() => {
    console.log("props", props?.userData);
  }, [props?.userData]);

  const decryptionKey = process.env.REACT_APP_SECRET_KEY;
  const decryptedEmail = CryptoJS.AES.decrypt(props?.userData?.user?.email, decryptionKey).toString(CryptoJS.enc.Utf8);

  const capitalizedCategory = capitalizeFirstLetter(props?.userData?.category?.name);
  const capitalizedName = capitalizeFirstLetter(props?.userData?.user?.name);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={"paper"}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <div className={styles.container}>
        <div className={styles.containerContent}>
          <form>
            <div className={styles.header}>
              <h1>Content Details</h1>
              <IconButton onClick={props.onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </div>

            <div className={styles.content}>
              <div className={styles.row}>
                <TextField
                  autoComplete="Username"
                  name="Username"
                  variant="outlined"
                  className={styles.textField}
                  id="Username"
                  label="Name"
                  value={capitalizedName}
                />
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  className={styles.textField}
                  id="email"
                  label="Email"
                  value={decryptedEmail}
                />
              </div>

              <div className={styles.row}>
                <TextField
                  autoComplete="ContentType"
                  name="ContentType"
                  variant="outlined"
                  className={styles.textField}
                  id="ContentType"
                  label="Content Type"
                  value={props?.userData?.contentType}
                />
                <TextField
                  autoComplete="Category"
                  name="Category"
                  variant="outlined"
                  className={styles.textField}
                  id="Category"
                  label="Category"
                  value={capitalizedCategory}
                />
              </div>

              <div className={styles.row} style={{ marginBottom: "40px" }}>
                <video
                  src={props?.userData?.promoVideo}
                  style={{ width: "100%", maxWidth: "500px", height: "300px", borderRadius: "5px" }}
                  controls
                />
                <TextField
                  autoComplete="Tags"
                  name="Tags"
                  variant="outlined"
                  className={styles.textField}
                  id="Tags"
                  label="Tags"
                  value={props?.userData?.tags}
                  style={{ marginTop: "16px", width: "100%", maxWidth: "560px" }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});
export default connect(mapStateToProps)(CategoryModal);
