import React, { useEffect, useState } from "react";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import SelectComp from "../../utils/Select/SelectComp";
import { putRawData } from "../../../restfulservices/httpHandler";
import style from "./styles.module.css";

const VerifyModal = (props) => {
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await putRawData(`v1/user/updateUserStatus/${props?.userData?._id}`, {
      isVerified: status,
    });
    props.setAlert("Status Updated");
    setLoading(false);
    props.onDone();
  };

  useEffect(() => {
    if (props.type === "EDIT") {
      console.log("Setting status from props:", props?.userData?.isVerified);
      setStatus(props?.userData?.isVerified);
    }
  }, [props]);

  const handleSelectChange = (event) => {
    const newStatus = event.target.value;
    console.log("New status selected:", newStatus);
    setStatus(newStatus);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={style.container}>
        <div className={style.containerContent}>
          <form onSubmit={handleSubmit}>
            <div className={style.header}>
              <h1>Edit Verification</h1>
              <IconButton onClick={props.onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </div>

            <div className={style.content}>
              <div className={style.row}>
                <SelectComp
                  name="verificationStatus"
                  variant="outlined"
                  id="type"
                  className={style.textField}
                  value={status}
                  label="Verification Status"
                  onChange={handleSelectChange}
                  options={["Pending", "Verified"]}
                  values={["Pending", "Verified"]}
                />
              </div>
            </div>
            <Divider />

            <div className={style.footer}>
              {loading ? (
                <Button variant="contained" color="secondary">
                  Loading....
                </Button>
              ) : (
                <Button type="submit" variant="contained" color="secondary">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});

export default connect(mapStateToProps)(VerifyModal);
