import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = {
    container: {
        position : "absolute",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}
const BackDrop = () => {
    return (
        <div style={{...styles.container}}>
            <CircularProgress color="primary" />
        </div>
    )
}

export default BackDrop;