import axios from "axios";

export const loginApiData = async (formData) => {
  try {
    console.log(formData, "formdata")
    const res = await axios.post("v1/auth/login", formData, { nonAuth: true });
    console.log(res, "response")
    return res;
  } catch (e) {
    console.error(e);
  }
};
export const registerApiData = async (formData) => {
  try {
    const res = await axios.post("v1/auth/register", formData, { nonAuth: true });
    return res;
  } catch (e) {
    console.error(e);
  }
};
