import React from "react";

// core components
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
import Table from "../../views/Table/Table.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
import moment from "moment";
import CardBody from "../../views/Card/CardBody.js";

import style from "./styles.module.css";
// import Button from "@material-ui/core/Button";
// import GiftModal from "./Modal/GiftModal";
// import IconButton from "@material-ui/core/IconButton";
// import EditRoundedIcon from "@material-ui/icons/EditRounded";
// import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
// import InputRoundedIcon from '@material-ui/icons/InputRounded';
import Snackbar from "../utils/snackbar/Snackbar";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
// import TopBar from "../utils/Topbar/TopBar.js";
import { connect } from "react-redux";
// import ConfirmAlert from "../utils/ConfirmAlert/ConfirmAlert.js";
// import SelectComp from "../utils/Select/SelectComp";
// import Dropdown from "react-mui-multiselect-dropdown";
import { useEffect, useState } from "react";
import { getData } from "../../restfulservices/httpHandler.js";
import { Pagination } from "@material-ui/lab";
// import { Tooltip } from "@material-ui/core";
// import TicketModal from "./Modal/TicketModal.js";

// import Table from '@material-ui/core/Table';

const Ticket = (props) => {
  // const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = React.useState(false);
  // const [searchValue, setSearchValue] = React.useState("");
  const [ticketcount, setticketCount] = useState(null);
  // const [selectValue, setSelectValue] = React.useState("ALL");
  const [alert, setAlert] = React.useState(false);
  // const [addModal, setAddModal] = React.useState(false);
  // const [editModal, setEditModal] = React.useState(false);
  // const [activeUser, setActiveUser] = React.useState(false);
  // const [userType, setUserType] = React.useState([]);
  const [page, setPage] = React.useState(1);

  let limit = 20;

  useEffect(() => {
    fetchCategory();
  });

  const fetchCategory = async () => {
    //fetch user
    const res = await getData(
      `v1/ticket/getAllBuyTicket?page=${page}&limit=${limit}`
    );
    console.log("this is all Tickets", res);
    console.log(res?.data?.data);

    setFilteredData(res?.data?.data?.history);
    setticketCount(res?.data?.data?.count);
  };

  // const handleDelete = async (value) => {
  //   console.log(value);
  //   const res = await deleteData(`v1/category/deleteGiftById?giftId=${value}`);

  //   console.log("deleted", res);
  //   setAlert("Deleted Successfully");
  // };

  const formatDate = (date) => {
    const res = moment().format("MMM Do YY");
    return res;
  };
  const isLoading = !filteredData;
  const showData = !isLoading;
  const details = [];

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  !isLoading &&
    filteredData.forEach((val,index) => {
      details.push([
        index+1,
        val?.userId?.name,
        val?.price,
        val?.userId?.role,
        val?.basicTicketNo,
        val?.royalTicketNo,
        val?.vipTicketNo,
        formatDate(val?.createdAt),

        // <React.Fragment>
        //   <Tooltip title="Edit">
        //     <IconButton
        //       onClick={() => {
        //         setActiveUser(val);
        //         setEditModal(true);
        //       }}
        //     >
        //       <EditRoundedIcon />
        //     </IconButton>
        //   </Tooltip>

        //   <ConfirmAlert
        //     msg={`Are you sure you want delete ?`}
        //     onClickEvent={() => handleDelete(val._id)}
        //   >
        //     <Tooltip title="Delete">
        //       <IconButton style={{ color: "red" }}>
        //         <DeleteRoundedIcon />
        //       </IconButton>
        //     </Tooltip>
        //   </ConfirmAlert>
        // </React.Fragment>,
      ]);
    });

  return (
    <GridContainer>
      {/* <GiftModal
        type="ADD"
        open={addModal}
        onClose={() => setAddModal(false)}
        onDone={() => {
          setAddModal(false);
          fetchCategory();
        }}
        setAlert={(msg) => setAlert(msg)}
      /> */}
      {/* {activeUser && (
        <TicketModal
          type="EDIT"
          userData={activeUser}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
      )} */}

      <Snackbar open={alert} handleClose={() => setAlert(false)} />

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={style.cardHeader}>
              <h4>Ticket History</h4>
              {/* <Button
                color="secondary"
                variant="contained"
                onClick={() => setAddModal(true)}
              >
                Add Ticket
              </Button> */}
            </div>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <div className={style.loader}>
                <CircularProgress />
              </div>
            )}

            {showData && (
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "S.N",
                  "Name",
                  "Price",
                  "Role",
                  "basicTicketNo",
                  "royalTicketNo",
                  "vipTicketNo",
                  "Buy At",
                ]}
                tableData={details}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <div
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          count={Math.ceil(parseInt(ticketcount) / limit)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});
export default withRouter(connect(mapStateToProps)(Ticket));
