import React, { useEffect, useState } from "react";
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
// import moment from "moment";
import CardBody from "../../views/Card/CardBody.js";
import NotificationCard from "./NotificationCard.js";
import { Pagination } from "@material-ui/lab";
import { getData, putRawData } from "../../restfulservices/httpHandler.js";
import styles from "./styles.module.css";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchNotifications();
  }, [page]);

  const fetchNotifications = async () => {
    try {
      const response = await getData(`v1/notification/getAllNotificationforAdmin?page=${page}&limit=${limit}`);
      const notificationsData = response?.data?.data?.data || [];
      const totalCount = response?.data?.data?.totalCount || 0;

      setNotifications(notificationsData);
      // setTotalPages(response?.data?.data?.data);
      setTotalPages(Math.ceil(parseInt(totalCount) / limit));
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const markAsRead = async (id) => {
    try {
      await putRawData(`v1/notification/markReadNotification`, { id });
      setNotifications((prev) => prev.map((notif) =>
        notif._id === id ? { ...notif, markedAs: "Read" } : notif
      ));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={styles.cardHeader}>
              <h4>All Notifications</h4>
            </div>
          </CardHeader>
          <CardBody>
            <div className={styles.container}>
              <div className={styles.containerContent}>
                <div className={styles.content}>
                  {notifications.map((notification, index) => (
                    <NotificationCard
                      key={index}
                      id={notification._id}
                      title={notification.title}
                      message={notification.message}
                      markedAs={notification.markedAs}
                      type={notification.type}
                      markAsRead={markAsRead}
                    />
                  ))}
                  <Pagination
                    count={4}
                    page={page}
                    onChange={handlePageChange}
                    className={styles.pagination}
                    sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Notifications;
