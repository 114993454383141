import React from 'react';
import styles from './register.module.css';
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {withRouter} from 'react-router-dom'
import Snackbar from '../../utils/snackbar/Snackbar';
import {setUser} from '../../../containers/app/actions'
import { connect } from 'react-redux';
import {registerApiData} from '../../../restfulservices/auth.api'

const Register = (props) => {
    const [formData, setFormData] = React.useState({
        name: "",
        email: "",
        password: "",
        repeat_password:"",
        contact: "",
        address: "",
        role: "Admin",
        
    });
    const [err,setErr] = React.useState({
        email: false,
        password: false,
        name: false,
        contact: false,
        address: false
    })
    const [loading,setLoading] = React.useState(false)
    const [alert,setAlert] = React.useState(false);
    

    React.useEffect(() => {
        if(props.user) {
            props.history.push("/admin/dashboard")
        }
    },[props.user])

    const validate = () => { 
        let valid = true;
        let error = {email: false,password: false,name: false, contact: false,address: false};
        Object.keys(formData).map(key => {
            if(formData[key] === "") { 
                error[key] = `* ${key} field cannot be empty`;
                valid = false;
            }
        });
        setErr({...error})
        return valid;
    } 

    const onSubmit = () => {
        if (validate()) {
            setLoading(true);
            console.log(formData)
            registerApiData(formData)
                .then(res => {
                    console.log(res)
                    if(res.data.status) {
                        setAlert("Register Successfully");
                        localStorage.setItem("user",JSON.stringify(res.data.data.contact))
                        props.setUser(res.data.data)
                        props.history.push("/admin/dashboard")
                    } else { 
                        setAlert(res.data.msg)
                    }
                    setLoading(false); 
                    
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false);
                    setAlert(err)
                })
        }
            }
    return (
        <div className={styles.container}>
            <Snackbar open={alert} onClose={() => setAlert(false)} />
            <div className={styles.content}>
                <Formik initialValues={formData}
                    onSubmit={(values, { setSubmitting }) => {
                        onSubmit(values)
                        setSubmitting(false);
                    }}
                >
                    {({ handleChange, isSubmitting }) => (
                        <Form>
                            <Paper className={styles.paper} elevation={3}>
                    <h1>Register</h1>

                    <TextField 
                        value={formData.name}
                        label="Enter Name"
                        type="text"
                        onChange={(e) => setFormData({...formData,name: e.target.value})}
                        helperText={err.name}
                        error={err.name}
                        className={styles.textField}
                        fullWidth
                    />


                    <TextField 
                        value={formData.email}
                        label="Enter Email"
                        type="email"
                        onChange={(e) => setFormData({...formData,email: e.target.value})}
                        helperText={err.email}
                        error={err.email}
                        className={styles.textField}
                        fullWidth
                    />
                     <TextField 
                        value={formData.password}
                        type="password"
                        label="Enter Password"
                        onChange={(e) => setFormData({...formData,password: e.target.value})}
                        helperText={err.password}
                        error={err.password}
                        className={styles.textField}
                        fullWidth
                                />
                                 <TextField 
                        value={formData.repeat_password}
                        type="password"
                        label="Renter Password"
                        onChange={(e) => setFormData({...formData,repeat_password: e.target.value})}
                        helperText={err.password}
                        error={err.password}
                        className={styles.textField}
                        fullWidth
                    />

                    <TextField 
                        value={formData.contact}
                        label="Enter Contact"
                        type="number"
                        onChange={(e) => setFormData({...formData,contact: e.target.value})}
                        helperText={err.contact}
                        error={err.contact}
                        className={styles.textField}
                        fullWidth
                    />

                    <TextField  
                        value={formData.address}
                        label="Enter Address"
                        type="text"
                        onChange={(e) => setFormData({...formData,address: e.target.value})}
                        helperText={err.address}
                        error={err.address}
                        className={styles.textField}
                        fullWidth
                    />
                    <TextField  
                        value={formData.role}
                        label="Role"
                        type="text"
                        onChange={(e) => setFormData({...formData,role: e.target.value})}
                        helperText={err.role}
                        error={err.role}
                        className={styles.textField}
                        fullWidth
                    />


                   

                    <div className={styles.contentSpace}>
                        <Button color="primary" onClick={() => props.history.push("/login")}>Login Here</Button>
                        {loading 
                            ?
                        <Button variant="contained" color="primary">Loading...</Button>
                            :
                        <Button variant="contained" color="primary" disabled={isSubmitting} type="submit" >Register</Button>}
                    </div>
                </Paper>

                        </Form>
                    )}

                </Formik>
                
            </div>         
        </div>
    )
}
const mapStateToProps = state => ({
    user: state.appReducer.user
});
export default withRouter(connect(mapStateToProps,{setUser})(Register));