import * as actionTypes from './actionTypes'
import axios from 'axios'
import {storage} from '../../App'

export const setUser = (user) => dispatch => {
    dispatch({
        type: actionTypes.SET_USER,
        payload: user
    })
}
