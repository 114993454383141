import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../views/Navbars/Navbar.js";
import Footer from "../views/Footer/Footer.js";
import Sidebar from "../views/Sidebar/Sidebar.js";
import FixedPlugin from "../views/FixedPlugin/FixedPlugin.js";

import routes from "../routes.js";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "../assets/img/carclean.jpg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else if (!prop.layout) {
        // Handle public routes
        return (
          <Route exact path={prop.path} component={prop.component} key={key} />
        );
      }
      return null;
    })}
    <Redirect from="/" to="/admin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

function Admin(props) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [sticked, setSticked] = React.useState(false);

  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const changeSticked = (isSticky) => {
    setSticked({ sticked: isSticky });
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (props.user) {
      console.log("user" + props.user);
      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = "hidden";
      }
      window.addEventListener("resize", resizeFunction);
      // Specify how to clean up after this effect:
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
        }
        window.removeEventListener("resize", resizeFunction);
      };
    } else {
      props.history.push("/login");
    }
  }, [mainPanel, props.user]);
  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel} ref={mainPanel}>
        {/* <StickyHeader onSticky={(sticky) => {changeSticked(sticky);}}> */}
        <Navbar
          routes={routes}
          image={image}
          color={color}
          handleDrawerToggle={handleDrawerToggle}
          {...props}
        />
        {/* </StickyHeader> */}
        <Sidebar
          routes={routes}
          // logoText={"SmileKart"}
          // logo={logo}
          // image={image}
          // backgroundColor="blue"
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...props}
        />
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>

        {getRoute() ? <Footer /> : null}
        <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});
export default withRouter(connect(mapStateToProps)(Admin));
