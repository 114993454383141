import React, { useEffect, useState } from "react";
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
import Table from "../../views/Table/Table.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
import CardBody from "../../views/Card/CardBody.js";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import style from "./styles.module.css";
import Snackbar from "../utils/snackbar/Snackbar.js";
import VerifiedIcon from '@mui/icons-material/Verified';
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { connect } from "react-redux";
import { getData, putRawData } from "../../restfulservices/httpHandler.js";
import { Tooltip } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import ConfirmAlert from "../utils/ConfirmAlert/ConfirmAlert.js";
import CryptoJS from "crypto-js";


const secretKey = process.env.REACT_APP_SECRET_KEY;

const decryptData = (encryptedData, secretKey) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedData) throw new Error("Decryption resulted in empty string");
    return decryptedData;
  } catch (error) {
    console.error("Decryption error:", error);
    return encryptedData; 
  }
};

const ManageUser = () => {
  const [filter, setFilter] = useState("All");
  // const [searchValue, setSearchValue] = React.useState("All");
  const [filteredData, setFilteredData] = React.useState(false);
  // const [selectValue, setSelectValue] = React.useState("ALL");
  const [usercount, setUserCount] = useState(null);
  const [alert, setAlert] = useState(false);
  const [page, setPage] = React.useState(1);

  let limit = 10;

  useEffect(() => {
    if (filter === "All") {
      getData(`v1/user/getAllUsers?page=${page}&limit=${limit}`).then((res) => {
        const users = res?.data?.data?.users || [];
        const decryptedUsers = users.map(user => ({
          ...user,
          name: decryptData(user.name, secretKey),
          contact: user.contact ? decryptData(user.contact, secretKey) : "",
          email: decryptData(user.email, secretKey)
        }));
        setFilteredData(decryptedUsers);
        setUserCount(res?.data?.data?.count);
      });
    } else {
      let space;
      if (filter === "Active2" || filter === "Active1") {
        space = "userStatus";
      }
     else {
        space = "liveApproved";
      }
      getData(
        `v1/user/getBlockedActive?page=${page}&limit=${limit}&${space}=${filter.charAt(
          filter.length - 1
        )}`
      ).then((res) => {
        const users = res?.data?.data?.users || [];
        const decryptedUsers = users.map(user => ({
          ...user,
          name: decryptData(user.name, secretKey),
          contact: user.contact ? decryptData(user.contact, secretKey) : "",
          email: decryptData(user.email, secretKey)
        }));
        setFilteredData(decryptedUsers);
        setUserCount(res?.data?.data?.count);
      });
    }
  }, [alert, page, filter]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handlestatus = async (id, status) => {
    const res = await putRawData(`v1/user/updateUserStatus/${id}`, {
      userStatus: status,
    });

    if (status === 1) {
      setAlert("Unblocked");
    } else {
      setAlert("Blocked");
    }
  };

  const handleLiveApproval = async (id, approval) => {
    const res = await putRawData(`v1/user/updateLiveApproved/${id}`, {
      liveApproved: approval,
    });
  
    if (approval === 1) {
      setAlert("User live approval status is Approved");
    } else if (approval === 0) {
      setAlert("User live approval status is Unapproved");
    } else if (approval === 2) {
      setAlert("User live approval status is Pending");
    }
  };
  

  const handleVerificationStatus = async (id, isVerified) => {
    // const newStatus = isVerified ? "0" :"1" ; 
    const res = await putRawData(`v1/user/updateUserStatus/${id}`, {
      isVerified: isVerified,
    });
  
    if (isVerified === "Verified") {
      setAlert("User status is verified");
    } else if( isVerified === "Unverified") {
      setAlert("User status is unverified ");
    }else{
      setAlert("User status is pending");
    }
 
  };
  const isLoading = !filteredData;
  const showData = !isLoading;
  const details = [];

  !isLoading &&
    filteredData.forEach((val,index) => {
      details.push([
        index + 1,
        val?.name,
        val?.username,
        val?.contact !== undefined ? String(val.contact) : "",
        val?.email,
      
        <React.Fragment>
          {val?.isVerified === "Verified" ? (
            <ConfirmAlert
              msg="Are you sure you want to unverify this user?"
              onClickEvent={() => handleVerificationStatus(val?._id, "Unverified")}
            >
              <Tooltip title="Verified">
                <IconButton onClick={() => {}}>
                  <VerifiedIcon style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
            </ConfirmAlert>
          ) : val?.isVerified === "Unverified" ? (
            <ConfirmAlert
              msg="Are you sure you want to verify this user?"
              onClickEvent={() => handleVerificationStatus(val?._id, "Verified")}
            >
              <Tooltip title="Unverified">
                <IconButton onClick={() => {}}>
                  <DangerousIcon style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </ConfirmAlert>
          ) : (
            <ConfirmAlert
              msg="Are you sure you want to verify this user?"
              onClickEvent={() => handleVerificationStatus(val?._id, "Verified")}
            >
              <Tooltip title="Pending">
                <IconButton onClick={() => {}}>
                  <HourglassEmptyRoundedIcon style={{ color: "orange" }} />
                </IconButton>
              </Tooltip>
            </ConfirmAlert>
          )}
        </React.Fragment>,
      
        val?.walletAmount,
      
        <React.Fragment>
          {val?.liveApproved === 1 ? (
            <ConfirmAlert
              msg="Are you sure you want to unapprove this user?"
              onClickEvent={() => handleLiveApproval(val?._id, 0)}
            >
              <Tooltip title="Approved">
                <IconButton onClick={() => {}}>
                  <CheckCircleOutlineIcon style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
            </ConfirmAlert>
          ) : val?.liveApproved === 0 ? (
            <ConfirmAlert
              msg="Are you sure you want to approve this user?"
              onClickEvent={() => handleLiveApproval(val?._id, 1)}
            >
              <Tooltip title="Unapproved">
                <IconButton onClick={() => {}}>
                  <BlockIcon style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </ConfirmAlert>
          ) : (
            <ConfirmAlert
              msg="Are you sure you want to approve this user?"
              onClickEvent={() => handleLiveApproval(val?._id, 1)}
            >
              <Tooltip title="Pending">
                <IconButton onClick={() => {}}>
                  <HourglassEmptyRoundedIcon style={{ color: "orange" }} />
                </IconButton>
              </Tooltip>
            </ConfirmAlert>
          )}
        </React.Fragment>,
      
        <React.Fragment>
          {val?.userStatus === 1 ? (
            <ConfirmAlert
              msg="Are you sure you want to block this user?"
              onClickEvent={() => handlestatus(val?._id, 2)}
            >
              <Tooltip title="Block">
                <IconButton onClick={() => {}}>
                  <CheckCircleOutlineIcon style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
            </ConfirmAlert>
          ) : (
            <ConfirmAlert
              msg="Are you sure you want to unblock this user?"
              onClickEvent={() => handlestatus(val?._id, 1)}
            >
              <Tooltip title="Unblock">
                <IconButton onClick={() => {}}>
                  <BlockIcon style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </ConfirmAlert>
          )}
        </React.Fragment>,
      ]);
      
    });

  return (
    <GridContainer>
      <Snackbar open={alert} handleClose={() => setAlert(false)} />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={style.cardHeader}>
              <h4>All Users</h4>
              <select
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="All">All</option>
                <option value="Active1">Active</option>
                <option value="Active2">Blocked</option>
                <option value="Approval1">Live Approved</option>
                <option value="Approval2">Pending for live approval</option>
              </select>
            </div>
          </CardHeader>

          <div className="mt-4 ml-4">
            <span className={style.totaluser}>Total Users: {usercount}</span>
          </div>
          <CardBody>
            {isLoading && (
              <div className={style.loader}>
                <CircularProgress />
              </div>
            )}

            {showData && (
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "S.N",
                  "Name",
                  "Username",
                  "Contact",
                  "Email",
                 "Verified/Pending",
                  "Wallet Amount",
                  "LiveApprove",
                  "Block/Unblock",
                ]}
                tableData={details}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <div
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          count={Math.ceil(parseInt(usercount) / limit)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});
export default withRouter(connect(mapStateToProps)(ManageUser));
