import React, { useEffect, useState } from "react";
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
import Table from "../../views/Table/Table.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
import CardBody from "../../views/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SpamIcon from "@mui/icons-material/ReportProblem";
import CategoryModal from "./Modal/CategoryModal.js";
import Snackbar from "../utils/snackbar/Snackbar.js";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { getData } from "../../restfulservices/httpHandler.js";
import { Tooltip } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CryptoJS from "crypto-js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


import style from "./styles.module.css";

const ManageContent = () => {
  const [filteredData, setFilteredData] = React.useState(false);
  const [usercount, setUserCount] = useState(null);
  const [alert, setAlert] = useState(false);
  const [page, setPage] = React.useState(1);
  const [activeButton, setActiveButton] = useState("upcoming");
  const [activeUser, setActiveUser] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [contentToSpam, setContentToSpam] = useState(null);

  const limit = 10;
  const decryptionKey = process.env.REACT_APP_SECRET_KEY;

  const fetchpastcontent = async () => {
    const res1 = await getData(
      `v1/content/getPastForDashboard?page=${page}&limit=${limit}`
    );
    setFilteredData(res1?.data?.data?.past);
    setUserCount(res1?.data?.data?.total);
  };

  const fetchupcomingcontent = async () => {
    const res2 = await getData(
      `v1/content/getUpcomingForDashboard?page=${page}&limit=${limit}`
    );
    setFilteredData(res2?.data?.data?.upcoming);
    setUserCount(res2?.data?.data?.total);
  };

  const markContentAsSpam = async (id) => {
    try {
      const response = await getData(`v1/content/markedContentAsSpam?id=${id}`);
      if (response?.status === 200) {
        setAlert("Content marked as spam successfully");
        fetchupcomingcontent();
      } else {
        setAlert("Failed to mark content as spam");
      }
    } catch (error) {
      setAlert("An error occurred while marking content as spam");
    }
  };

  useEffect(() => {
    fetchupcomingcontent();
  }, [page]);

  const handleChangePage = (event, value) => {
    setPage(value);
    console.log(value,"hahaaj");
  };

  const isLoading = !filteredData;
  const showData = !isLoading;
  const details = [];

  !isLoading &&
    filteredData.forEach((val, index) => {
      const decryptedEmail = CryptoJS.AES.decrypt(
        val?.user?.email,
        decryptionKey
      ).toString(CryptoJS.enc.Utf8);

      details.push([
        index + 1,
        val?.user?.name,
        decryptedEmail,
        val?.contentType,
        val?.category?.name,
        <React.Fragment>
          <Tooltip title="Details">
            <IconButton
              onClick={() => {
                setActiveUser(val);
                setEditModal(true);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>,
        <React.Fragment>
          <Tooltip title="Mark as Spam">
            <IconButton onClick={() => {
              setContentToSpam(val._id);
              setDialogOpen(true);
            }}>
              <SpamIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>,
      ]);
    });

  const handleDialogClose = () => {
    setDialogOpen(false);
    setContentToSpam(null);
  };

  const handleConfirmSpam = () => {
    markContentAsSpam(contentToSpam);
    handleDialogClose();
  };

  return (
    <GridContainer>
      {activeUser && (
        <CategoryModal
          type="EDIT"
          userData={activeUser}
          open={editModal}
          onClose={() => {
            setEditModal(false);
            setActiveUser(false);
          }}
          onDone={() => {
            setEditModal(false);
            setActiveUser(false);
          }}
          setAlert={(msg) => setAlert(msg)}
        />
      )}
      <Snackbar
        open={alert}
        handleClose={() => setAlert(false)}
        message={alert}
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={style.cardHeader}>
              <h4>Content Management</h4>
              <div className={style.buttons}>
                <Button
                  color={activeButton === "upcoming" ? "secondary" : "white"}
                  variant="contained"
                  onClick={() => {
                    setActiveButton("upcoming");
                    fetchupcomingcontent();
                  }}
                >
                  Upcoming
                </Button>
                <Button
                  color={activeButton === "past" ? "secondary" : "white"}
                  variant="contained"
                  onClick={() => {
                    setActiveButton("past");
                    fetchpastcontent();
                  }}
                >
                  Past
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <div className={style.loader}>
                <CircularProgress />
              </div>
            )}
            {showData && (
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "S.N",
                  "Name",
                  "Email",
                  "Content Type",
                  "Category",
                  "View",
                  "Spam",
                ]}
                tableData={details}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      
      
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="confirm-dialog-title"
      >
        <DialogTitle id="confirm-dialog-title">Confirm Action</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to mark this content as spam?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSpam} color="primary">
            Confirm
          </Button>
        </DialogActions>
        
      </Dialog>
      <div
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          count={Math.ceil(parseInt(usercount) / limit)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </GridContainer>
   
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});

export default withRouter(connect(mapStateToProps)(ManageContent));
