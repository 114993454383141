import React from "react";

// core components
import GridItem from "../../views/Grid/GridItem.js";
import GridContainer from "../../views/Grid/GridContainer.js";
import Table from "../../views/Table/Table.js";
import Card from "../../views/Card/Card.js";
import CardHeader from "../../views/Card/CardHeader.js";
import CardBody from "../../views/Card/CardBody.js";

import style from "./styles.module.css";
import Button from "@material-ui/core/Button";
import CategoryModal from "./Modal/CategoryModal";
import IconButton from "@material-ui/core/IconButton";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
// import InputRoundedIcon from '@material-ui/icons/InputRounded';
import Snackbar from "../utils/snackbar/Snackbar";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import TopBar from "../utils/Topbar/TopBar.js";
import { connect } from "react-redux";
import ConfirmAlert from "../utils/ConfirmAlert/ConfirmAlert.js";
import SelectComp from "../utils/Select/SelectComp";
import Dropdown from "react-mui-multiselect-dropdown";
import { useEffect, useState } from "react";
import { deleteData, getData } from "../../restfulservices/httpHandler.js";
import { Pagination } from "@material-ui/lab";
import { Tooltip } from "@material-ui/core";

// import Table from '@material-ui/core/Table';

const Category = (props) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [categoryCount, setCategoryCount] = useState(null);
  const [selectValue, setSelectValue] = React.useState("ALL");
  const [alert, setAlert] = React.useState(false);
  const [addModal, setAddModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [activeUser, setActiveUser] = React.useState(false);
  const [userType, setUserType] = React.useState([]);
  const [page, setPage] = React.useState(1);

  let limit = 10;

  useEffect(() => {
    fetchCategory();
  }, [alert]);

  //   useEffect(() => {
  //     if (searchValue) {
  //       SearchUserData(searchValue);
  //     } else {
  //       fetchCategory();
  //     }
  //   }, [searchValue, selectValue]);

  const fetchCategory = async () => {
    //fetch user
    const res = await getData(
      `v1/category/getAllCategory?page=${page}&limit=${limit}`
    );
    console.log("this is all categories", res);
    setFilteredData(res?.data?.data?.category);
    setCategoryCount(res?.data?.data?.count);
  };
  //   const SearchUserData = (Searchid) => {
  //     SearchUser(Searchid)
  //       .then((res) => {
  //         if (res.data.status) {
  //           setData(res.data.data.users);
  //           setFilteredData(res.data.data.users);
  //         } else {
  //           setAlert(res.data.error);
  //           setFilteredData();
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  const handleDelete = async (value) => {
    console.log(value);
    const res = await deleteData(
      `v1/category/deleteCategoryById?categoryId=${value}`
    );
    console.log("deleted", res);
    setAlert("Deleted Successfully");
  };
  const isLoading = !filteredData;
  const showData = !isLoading;
  const details = [];

  // data && data.forEach(val => {
  //   userTypes.push(val.userType);
  // });
  // userTypes = [...new Set(userTypes)]
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  !isLoading &&
    filteredData.forEach((val, index) => {
      var type;
      // val.user_roles.map((value) => {
      //       type = value.user_type.type_name
      //     })
      details.push([
        // val.user_barcode,
        index + 1,
        val.name,
        val.description,
        val.status,

        <React.Fragment>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                setActiveUser(val);
                setEditModal(true);
              }}
            >
              <EditRoundedIcon />
            </IconButton>
          </Tooltip>

          {/* <ConfirmAlert
            msg={`Are you sure you want delete ?`}
            onClickEvent={() => handleDelete(val._id)}
          >
            <Tooltip title="Delete">
              <IconButton style={{ color: "red" }}>
                <DeleteRoundedIcon />
              </IconButton>
            </Tooltip>
          </ConfirmAlert> */}
        </React.Fragment>,
      ]);
    });

  return (
    <GridContainer>
      <CategoryModal
        type="ADD"
        open={addModal}
        onClose={() => setAddModal(false)}
        onDone={() => {
          setAddModal(false);
          fetchCategory();
        }}
        setAlert={(msg) => setAlert(msg)}
      />
      {activeUser && (
        <CategoryModal
          type="EDIT"
          userData={activeUser}
          open={editModal}
          onClose={() => setEditModal(false)}
          onDone={() => {
            setEditModal(false);
            setActiveUser(false);
            fetchCategory();
          }}
          setAlert={(msg) => setAlert(msg)}
        />
      )}

      <Snackbar open={alert} handleClose={() => setAlert(false)} />

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={style.cardHeader}>
              <h4>All Category</h4>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setAddModal(true)}
              >
                Add Category
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <div className={style.loader}>
                <CircularProgress />
              </div>
            )}

            {showData && (
              <Table
                tableHeaderColor="primary"
                tableHead={["S.N", "Name", "Description", "Status", "Action"]}
                tableData={details}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <div
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          count={Math.ceil(parseInt(categoryCount) / limit)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.appReducer.user,
});
export default withRouter(connect(mapStateToProps)(Category));
